import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/:urlmsg?",
    name: "GetCode",
    // redirect: "/index",
    // meta: { hidden: true, title: "智慧停车" },
    component: () => import("../views/getCode/index.vue"),
    meta: {
      allowBack: true,
      hidden: true,
      title: "智慧停车",
    },
  },
  // {
  //   path: `/index`,
  //   // redirect: "",
  //   name: "GetCode",
  //   component: () => import("../views/getCode/index.vue"),
  //   meta: { hidden: true, title: "智慧停车", allowBack: false },
  // },
  {
    path: "/inpark/parkingpay",
    name: "Parkingpay",
    component: () => import("../views/newparkingpay/index.vue"),
    meta: { allowBack: true, hidden: true, title: "停车收费" },
  },
  {
    path: "/inpark/oldparkingpay",
    name: "Parkingpay",
    component: () => import("../views/parkingpay/index.vue"),
    meta: { allowBack: true, hidden: true, title: "停车收费" },
  },
  {
    path: "/inpark/deepdetails",
    name: "DeepDetails",
    component: () => import("../views/newparkingpay/components/deepDetails.vue"),
    meta: { allowBack: true, hidden: true, title: "订单详情" },
  },
  {
    path: "/inpark/success",
    name: "Success",
    component: () => import("../views/payState/success.vue"),
    meta: { allowBack: true, hidden: true, title: "支付成功" },
  },
  {
    path: "/inpark/fail",
    name: "Fail",
    component: () => import("../views/payState/fail.vue"),
    meta: { allowBack: true, hidden: true, title: "支付失败" },
  },
  {
    path: "/inpark/forensics",
    name: "Forensics",
    component: () => import("../views/forensics/index.vue"),
    meta: { allowBack: true, hidden: true, title: "扫码取证" },
  },
  {
    path: "/inpark/realparkrecord",
    name: "Realparkrecord",
    component: () => import("../views/newparkingpay/components/realParkDetail.vue"),
    meta: { allowBack: true, hidden: true, title: "实时订单详情" },
  },
  {
    path: "/outpark/parklotparknum",
    name: "ParkNo",
    component: () => import("../views/parkLot/parkno/index.vue"),
    meta: { allowBack: true, hidden: true, title: "停车缴费" },
  },
  {
    path: "/outpark/parklotcharging",
    name: "Charging",
    component: () => import("../views/parkLot/charging/index.vue"),
    meta: { allowBack: true, hidden: true, title: "停车缴费" },
  },
  {
    path: "/outpark/parklotdetail",
    name: "Charging",
    component: () => import("../views/parkLot/charging/components/parklotdetail.vue"),
    meta: { allowBack: true, hidden: true, title: "停车场欠费详情" },
  },
  //黑盒
  {
    path: "/outpark/blankBoxParklotparknum",
    name: "boxParkNo",
    component: () => import("../views/parkLot/blankBoxParkno/index.vue"),
    meta: { allowBack: true, hidden: true, title: "停车缴费" },
  },
  {
    path: "/outpark/blankBoxCharging",
    name: "boxCharging",
    component: () => import("../views/parkLot/blankBoxCharging/index.vue"),
    meta: { allowBack: true, hidden: true, title: "停车缴费" },
  },
  //
  {
    path: "/outpark/coupon",
    name: "Coupon",
    component: () => import("../views/coupon/index.vue"),
    meta: { allowBack: false, hidden: true, title: "领取停车优惠券" },
  },
  {
    path: "/outpark/couponsucess",
    name: "Couponsucess",
    component: () => import("../views/couponsuccess/index.vue"),
    meta: { allowBack: true, hidden: true, title: "领取成功" },
  },
  {
    path: "/outpark/arreardetails",
    name: "Couponsucess",
    component: () => import("../views/parkLot/charging/components/details.vue"),
    meta: { allowBack: true, hidden: true, title: "欠费详情" },
  },
  {
    path: "/outpark/monthcard",
    name: "Couponsucess",
    component: () => import("../views/monthCard/index.vue"),
    meta: { allowBack: true, hidden: true, title: "月卡办理" },
  },
  {
    path: "/outpark/exitcarcharge",
    name: "Couponsucess",
    component: () => import("../views/parkLot/exitcarCharge/index.vue"),
    meta: { allowBack: true, hidden: true, title: "停车缴费" },
  },
  {
    path: "/outpark/monitoringVideo",
    name: "Charging",
    component: () => import("../views/monitoringVideo/index.vue"),
    meta: { hidden: true, title: "监控" },
  },
  {
    path: "/outpark/video",
    name: "Video",
    component: () => import("../views/video/index.vue"),
    meta: { hidden: true, title: "一键呼叫" },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
